.wysiwig {
	h2 {
		font-size: 24px;
		font-weight: 900;
		margin-bottom: 24px;
	}

	h3 {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 3px;
	}

	h4 {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 3px;
	}

	p {
		font-size: 18px;
		font-weight: 300;
		line-height: 1.45em;
		overflow-wrap: break-word;
	}

	&--hidden {
		display: none;
	}

	&--more {
		margin-top: 30px;
	}

	@include m1023 {
		h2 {
			line-height: 1.3em;
			margin-bottom: 8px;
		}

		h3 {
			line-height: 1.4em;
			margin-bottom: 2px;
		}
	}
}
