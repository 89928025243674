.description {
    border-top: solid 1px rgba(255, 255, 255, 0.16);
    padding: 58px 0 38px;

    &_texte {
        color: $bright;
        font-weight: 400;
        font-size: 17px;
        line-height: 1.52em;
        letter-spacing: 0.022em;
        margin-bottom: 14px;
    }

    &_link {
        opacity: 1;
        max-height: 50px;
        transition: opacity 0.15s ease, max-height 0.15s ease;

        &--hidden {
            max-height: 0px;
            opacity: 0;
            pointer-events: none;
        }

        button {
            color: var(--colored);
            font-weight: 400;
            font-size: 16px;
            line-height: 1.625em;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &_more {
        margin-top: 20px;
        opacity: 0;
        pointer-events: none;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.35s ease-out, opacity 0.35s ease 0.15s;

        &--visible {
            opacity: 1;
            pointer-events: all;
            grid-template-rows: 1fr;
        }

        &_inner {
            overflow: hidden;
        }

        h3 {
            color: $bright;
            font-weight: 700;
            font-size: 17px;
            line-height: 1.833333em;
            margin-bottom: 6px;
            letter-spacing: -0.01em;
        }

        p {
            color: $bright;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.52em;
            margin-bottom: 20px;
        }

        a {
            color: var(--colored);
            font-style: italic;
            text-decoration: underline;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.52em;

            &:hover {
                text-decoration: none;
            }
        }

        blockquote {
            color: $bright;
            font-weight: 400;
            font-size: 29px;
            line-height: 1.4483em;
            text-decoration: underline;
            text-decoration-color: var(--colored);
            text-underline-offset: 10px;
            margin-bottom: 16px;
            letter-spacing: 0.01em;

            &+h3 {
                margin-bottom: 0px;
            }
        }
    }

    @include m1023 {
        padding-top: 45px;
        padding-bottom: 27px;

        &_texte {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.625em;
            letter-spacing: 0.0em;
        }

        &_more {
            h3 {
                font-size: 16px;
                line-height: 1.625em;
            }

            p {
                font-size: 16px;
                line-height: 1.625em;
            }

            a {
                font-size: 16px;
                line-height: 1.625em;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}