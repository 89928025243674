.flex_visuel {
    //margin-top: 39px;

    img{
        width: 100%;
    }

    @include m1023 {
        //margin-top: 25px;
    }
}