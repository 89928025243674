.headerPageChoix {
    text-align: center;
    background: linear-gradient(#005249, #009E8F);
    color: $bright;
    height: calc(100vh - 140px);

    &_inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 6.6vh;
        padding-bottom: 5.6vh;
    }

    &_logo {
        position: relative;
        width: 292px;
        height: 68px;

        img {
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &_choix1 {
        margin-bottom: 0vh;

        &Titre {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 32px;
        }
    }

    &_choix2 {
        margin-bottom: 8vh;
    }

    &_animation {
        width: 100%;
        height: 8.42vh;
        max-height: 91px;
        text-align: center;
        box-sizing: content-box;
        margin-top: 7.6vh;

        img {
            height: 100%;
            width: auto;
            max-width: none;
            display: inline;
        }

    }

    @include m1023 {
        height: auto;

        &_inner {
            height: auto;
            padding-top: 26px;
            padding-bottom: 43px;
        }

        &_logo {
            width: 154px;
            margin-bottom: 100px;
        }

        &_choix1 {
            &Titre {
                font-size: 30px;
                margin: 0 auto 15px;
                width: 220px;
            }
        }

        &_animation {
            height: auto;
            max-height: none;
            margin-top: 110px;
            margin-bottom: 78px;

            img {
                height: auto;
                width: 100%;
            }
        }
    }
}