@mixin playlist {
    background-color: $blue-dark;
    border-bottom: solid 1px transparent;
    color: $bright;

    &_intro {
        display: flex;
        border-bottom: solid 1px $blue-medium;
        padding-top: 43px;
        padding-bottom: 35px;
        align-items: center;
        pointer-events: none;
    }

    &_back {
        width: 20.2%;
        pointer-events: all;

        a {
            margin-left: 44px;
            margin-bottom: -49px;

            @include m1024 {
                position: absolute;
                top: 108px;
                left: 128px;
                z-index: 2;
            }
        }
    }

    &_infos {
        width: 40.7%;
        line-height: 1.2em;
    }

    &_titre {
        font-size: 22px;
        font-weight: 700;
    }

    &_nombres {
        font-size: 16px;
        font-weight: 700;
    }

    &_duree {
        font-size: 16px;
        font-style: italic;
    }

    &_startAll {
        margin-bottom: 1px;
        transform: translateY(-3px);
        pointer-events: all;
    }

    &_chapeau {
        font-size: 18px;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 66px;
    }

    &_contenus {

        margin-bottom: 40px;

        table {
            width: 100%;
        }

        thead {
            border-bottom: solid 1px $blue-medium;
        }

        &_label {
            font-size: 20px;
            color: $blue-medium;
            font-weight: 400;
            padding-bottom: 13px;

            &--duree {
                text-align: right;

                img {
                    display: inline-block;
                    margin-right: 13px;
                    margin-bottom: 3px;
                }
            }
        }

        &_item {
            &--current {
                background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
            }

            &:first-child {
                td {
                    padding-top: 12px;
                }
            }

            td:first-child {
                height: 117px;
            }

            &Index {
                width: 20%;

                vertical-align: middle;
                font-size: 22px;
                color: $blue-medium;

                strong {
                    font-weight: normal;
                    color: $bright;
                }
            }

            &Visuel {
                width: 94px;
                height: 94px;
                flex: 0 0 94px;
                margin-right: 63px;
                overflow: hidden;
                border-radius: 9px;

                &Box {
                    width: 100%;
                    height: 100%;
                    transform: scale(1.25);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center 40%;
                }
            }

            &Button {
                display: flex;
                align-items: center;
            }

            &Titre {
                vertical-align: middle;
                font-size: 22px;
            }

            &Label {
                line-height: 1.25em;
                margin-top: 3px;

                strong {
                    display: none;
                    color: $blue-medium;
                }
            }

            &Duree {
                vertical-align: middle;
                font-size: 22px;
                opacity: 0.75;
                text-align: right;
                padding-right: 0px;

                span {
                    margin-top: 8px;
                    display: block;
                }
            }
        }
    }

    &_button {
        text-align: center;
    }

    @include m1023 {
        &_intro {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 407px;
            border-bottom: none;
        }

        &_back {
            position: absolute;
            top: 25px;
            left: 6px;
            width: auto;
        }

        &_infos {
            position: absolute;
            top: 51px;
            left: calc(50% - 140px);
            right: calc(50% - 140px);
            width: auto;
            pointer-events: none;
            text-align: center;
        }

        &_titre {
            line-height: 1.2em;
            margin-bottom: 1px;
        }

        &_startAll {
            position: absolute;
            bottom: 21px;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);

            &--playing {
                display: none;
            }
        }

        &_chapeau {
            line-height: 1.05em;
            border-bottom: solid 1px rgba(255, 255, 255, 0.16);
            padding-bottom: 25px;
            margin-bottom: 16px;
            margin-top: 0;
            padding-top: 29px;
        }

        &_contenus {
            margin-bottom: 10px;

            table {
                margin: 0 -16px;
                width: calc(100% + 32px)
            }

            thead {
                display: none;
            }

            tbody {
                tr {
                    td:first-child {
                        display: none;
                    }

                    td:nth-child(2) {
                        height: 79px;
                        padding-top: 15px;
                        padding-bottom: 18px;
                    }

                    td:nth-child(2) {
                        padding-left: 16px;
                    }

                    td:last-child {
                        padding-right: 16px;
                    }
                }
            }

            &_item {
                &:first-child td {
                    padding-top: 0;
                }

                &--current {
                    background: rgba(255, 255, 255, 0.1);
                }

                &Button {
                    align-items: center;
                }

                &Visuel {
                    width: 59px;
                    height: 59px;
                    flex: 0 0 59px;
                    margin-right: 12px;
                }

                &Label {
                    font-size: 17px;
                    line-height: 1.15em;
                    margin-top: 0px;

                    strong {
                        font-size: 14px;
                        display: block;
                        line-height: 1.2em;
                        margin-bottom: 4px;
                    }
                }

                &Titre {
                    padding-right: 20px;
                }

                &Duree {
                    font-size: 17px;

                    span {
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}

.playlist {
    @include playlist;
}