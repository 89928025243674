.navigation {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: background 0.35s ease;
        z-index: 1;
    }

    &_logo {
        position: absolute;
        width: 32px;
        height: 32px;
        background: transparent url(/assets/img/logo3.png) no-repeat center center;
        background-size: contain;
        top: 47px;
        left: 116px;
        z-index: 5;
        pointer-events: all;
    }

    &--visible {
        pointer-events: all;

        &:after {
            background-color: rgba(0, 0, 0, 0.55);
        }
    }

    &_toggle {
        position: absolute;
        color: $bright;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.42857em;
        left: 162px;
        top: 53px;
        z-index: 5;
        padding-left: 50px;
        pointer-events: all;

        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 2px;
            width: 23px;
            background-color: $bright;
            left: 8px;
            transition: all 0.35s ease;
        }

        &:before {
            top: 3px;
        }

        &:after {
            top: 13px;
        }
    }

    &--visible &_toggle {
        &:before {
            transform: translateY(5px) rotate(45deg);
        }

        &:after {
            transform: translateY(-5px) rotate(-45deg);
        }

        &:hover {
            &:before {
                transform: translateY(5px) rotate(135deg);
            }

            &:after {
                transform: translateY(-5px) rotate(45deg);
            }
        }
    }

    &_content {
        width: 683px;
        max-width: 100%;
        overflow: auto;
        height: 100%;
        padding-top: 17.1vh;
        pointer-events: none;
        transform: translateX(-100%);
        transition: all 0.35s ease;
        background-color: #000;
        z-index: 3;
        position: relative;
    }

    &--visible &_content {
        pointer-events: all;
        transform: translate(0%);
    }

    &_firstLevel {

        &_liste {
            width: 386px;
            margin: 0 auto;
            max-width: 90%;
        }

        &_item {
            margin-bottom: 6.2vh;
            position: relative;
        }

        &_cta {
            font-size: 28px;
            line-height: 0.714em;
            font-weight: 400;
            width: 100%;
            text-align: left;

            &--button {
                &:after {
                    content: "";
                    position: absolute;
                    width: 2px;
                    height: 18px;
                    background-color: $bright;
                    top: 4px;
                    right: -27px;
                    transition: transform 0.35s ease;
                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    background: linear-gradient(#0000 0 8px,$bright 8px 10px,#0000 11px 18px);
                    top: 4px;
                    right: -35px;
                    transition: transform 0.35s ease;

                }

                &--selected:after {
                    transform: rotate(-90deg);
                }

                @include m1024 {
                    &:not(&--selected):hover {
                        &:before {
                            transform: rotate(90deg);
                        }

                        &:after {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }

    &_secondLevel {

        &_liste {
            padding: 0 2.7vh 0;
            max-height: 0;
            opacity: 0;
            transition: all 0.35s ease;
            pointer-events: none;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 0%;
                height: 2px;
                bottom: 12px;
                background-color: $blue;
                transition: all 0.35s ease;
                opacity: 0.23;
            }

            &--visible {
                padding: 3.2vh 2.7vh 7.9vh;
                max-height: 40vh;
                opacity: 1;
                pointer-events: all;

                &:after {
                    width: 86%;
                    transition: all 0.35s ease 0.15s;
                }
            }

            &.pink:after {
                background-color: $pink;
            }
        }

        &_item {
            margin-bottom: 2.4vh;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &_cta {
            font-size: 18px;
            line-height: 1.111111em;
            font-weight: 600;
        }
    }

    &_lien {
        position: relative;
        display: table-cell;

        &:before {
            content: "";
            width: 34px;
            height: 20px;
            position: absolute;
            right: -60px;
            top: 2px;
            background-image: url("data:image/svg+xml;utf8,<svg width='34' height='20' viewBox='0 0 34 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.5 10.5L16.5 10.5' stroke='white' stroke-width='2'/><path d='M11 3L18 10.5L11 18' stroke='white' stroke-width='2'/></svg>");
            background-size: cover;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 2;
            opacity: 0;
            transform: translateX(-50%);
            transition: all 0.35s ease;
        }

        &:after {
            content: "";
            width: 17px;
            height: 17px;
            position: absolute;
            background-color: $blue;
            border-radius: 50%;
            z-index: 1;
            right: -57px;
            top: 4px;
            opacity: 0;
            transform: scale(0);
            transition: all 0.35s ease;
        }

        &.pink:after {
            background-color: $pink;
        }

        &:hover {
            &:before {
                opacity: 1;
                transform: translateX(0%);
            }

            &:after {
                opacity: 1;
                transform: scale(1);
            }
        }

        &.navigation_firstLevel_cta {
            &:before {
                top: 2px;
            }

            &:after {
                top: 4px;
            }
        }
    }

    &_lien2 {
        display: table-cell;

        &:before {
            content: "";
            width: 34px;
            height: 20px;
            position: absolute;
            right: -48px;
            top: 2px;
            background-image: url("data:image/svg+xml;utf8,<svg width='34' height='20' viewBox='0 0 34 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.5 10.5L16.5 10.5' stroke='white' stroke-width='2'/><path d='M11 3L18 10.5L11 18' stroke='white' stroke-width='2'/></svg>");
            background-size: cover;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 2;
            opacity: 0;
            transform: translateX(-50%);
            transition: all 0.35s ease;
        }

        &:hover {
            &:before {
                opacity: 1;
                transform: translateX(0%);
            }
        }
    }

    @include m1023 {
        right: 0;
        position: absolute;
        top: -15px;
        height: 100vh;
        transition: position 0s ease 3s;

        &--visible{
            position: fixed;
            top: 0;
            transition: position 0s ease 3s;
        }

        @keyframes position {
            0%{
                position: fixed;
            }

            100%{
                position: absolute;
            }
        }

        &:before {
            top: 20px;
            left: 20px;
        }

        &_toggle {
            left: 58px;
            top: 26px;
            text-indent: -999px;

            &:after{
                top: 10px;
            }
            &:before,&:after{
                width: 20px;
            }
            
        }

        &--visible &_toggle{
            &:before{
                top: 0;
            }
        }

        &_content {
            padding-top: 12.4vh;
        }

        &_logo{
            top: 17px;
            left: 20px;
        }

        &_firstLevel {
            &_liste {
                max-width: 88%;
            }

            &_item {
                margin-bottom: 4.9vh;
            }

            &_cta {
                font-size: 24px;
                line-height: 1.41666em;
                max-width: 70vw;

                &--button {
                    &:before {
                        top: 7px;
                        right: 1px;
                    }

                    &:after {
                        top: 7px;
                        right: 9px;
                    }
                }
            }
        }

        &_secondLevel {
            &_liste {
                padding: 0 7vh 0 4vh;

                &--visible {
                    padding: 4.9vh 7vh 8.8vh 4vh;

                    &:after {
                        width: 100%;
                    }
                }
            }

            &_item {
                margin-bottom: 3.9vh;
            }

            &_cta {
                font-size: 16px;
                line-height: 1.25em;
            }
        }

        &_lien {
            &:before {
                top: 0;
            }

            &:after {
                top: 2px;
            }

            &.navigation_firstLevel_cta {
                &:before {
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &:after {
                    top: 50%;
                    transform: translateY(-50%) scale(0);
                }

                &:hover {
                    &:before {
                        top: 50%;
                        transform: translate(0%, -50%);
                    }

                    &:after {
                        top: 50%;
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }

        &_lien2 {
            &:before {
                right: -11px;
            }
        }
    }
}