.otherPushs {
    margin-top: 176px;
    margin-bottom: 100px;

    &_liste {
        display: flex;
        justify-content: flex-start;
    }

    &_item {
        border: solid 1px rgba(255, 255, 255, 0.16);
        border-radius: 10px;
        flex: 0 0 31.1%;
        position: relative;
        margin-right: 0;

        &>a {
            display: block;
            height: 100%;
            padding-bottom: 110px;
        }

        &:nth-child(1),
        &:nth-child(2) {
            margin-right: 3.35%;
        }
    }

    &_visuel {
        height: 142px;
        width: 100%;
        border-radius: 10px 10px 0 0;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
            transition: transform 0.35s ease;
        }
    }

    @include m1024 {

        &_item>a:hover &_visuel img,
        &_item>a:focus &_visuel img {
            transform: scale(1.2);
        }
    }

    &_content {
        padding: 45px 23px 0;
    }

    &_titre {
        color: $bright;
        font-weight: 800;
        font-size: 22px;
        line-height: 1.3636em;
        margin-bottom: 17px;
        letter-spacing: 0.01em;
    }

    &_texte {
        color: $grey2;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.36375em;
    }

    &_link {
        color: $grey2;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.625em;
        position: absolute;
        bottom: 42px;
        display: flex;
        width: 100%;

        span {
            flex: 0 0 auto;
            position: relative;
            max-width: calc(100% - 120px);

            &:before {
                content: "";
                position: absolute;
                right: -62px;
                top: 50%;
                transform: translateY(-48%);
                background-color: var(--colored);
                opacity: 0;
                border-radius: 50%;
                width: 46px;
                height: 46px;
                transition: opacity 0.35s ease;
            }

            &:after {
                content: "";
                position: absolute;
                right: -39px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                border-top: solid 2px var(--colored);
                border-right: solid 2px var(--colored);
                width: 10px;
                height: 10px;
            }
        }
    }

    @include m1024 {
        &_item>a:hover &_link span:before,
        &_item>a:focus &_link span:before {
            opacity: 0.3;
        }
    }

    @include m1023 {
        margin-top: 85px;

        &_liste {
            flex-direction: column;
        }

        &_item {
            &:nth-child(n) {
                margin-right: 0;
                margin-bottom: 31px;
            }

            &>a {
                padding-bottom: 30px;
                position: relative;
            }
        }

        &_content {
            padding: 51px 15px 0;
        }

        &_titre {
            font-size: 18px;
            line-height: 1.363888em;
        }

        &_texte {
            color: $grey2;
            font-size: 14px;
            line-height: 1.3643em;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 60px;
        }

        &_link {
            bottom: 54px;
            right: 78px;

            span {
                display: none;
            }

            &:before {
                content: "";
                position: absolute;
                right: -62px;
                top: 50%;
                transform: translateY(-48%);
                background-color: var(--colored);
                opacity: 0;
                border-radius: 50%;
                width: 46px;
                height: 46px;
                transition: opacity 0.35s ease;
            }

            &:after {
                content: "";
                position: absolute;
                right: -39px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                border-top: solid 2px var(--colored);
                border-right: solid 2px var(--colored);
                width: 10px;
                height: 10px;
            }
        }

        &_item>a &_link:before {
            opacity: 0.3;
        }
    }
}