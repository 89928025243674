.intro {
    text-align: center;
    padding: 32px 0 60px;

    &_titre {
        color: var(--colored);
        font-weight: 700;
        font-size: 16px;
        line-height: 1.6875em;
        margin-bottom: 33px;
    }

    &_chapeau {
        color: $bright;
        font-weight: 700;
        font-size: 22px;
        line-height: 1.272727em;
        max-width: 700px;
        margin: 0 auto 17px;
        letter-spacing: 0.015em;
    }

    &_texte {
        color: $bright;
        font-weight: 400;
        font-size: 17px;
        line-height: 1.5294em;
        max-width: 750px;
        margin: 0 auto;
    }

    @include m1023{
        padding-top: 33px;
        
        &_titre {
            margin-bottom: 33px;
        }

        &_chapeau {
            font-size: 20px;
            line-height: 1.5em;
            margin: 0 auto 17px;
        }

        &_texte {
            line-height: 1.5882em;
            margin: 0 auto;
        }
    }
}