.accordeon {
    border-bottom: solid 1px rgba(255, 255, 255, 0.16);
    margin-bottom: 201px;

    &_intro {
        text-align: center;
        border-top: solid 1px rgba(255, 255, 255, 0.16);
        padding: 46px 0 78px;

        &--nomargin{
            padding-bottom: 4px;
        }
    }

    &_titre {
        color: $bright;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 43px;
        letter-spacing: 0.015em;
    }

    &_item {
        display: inline-block;

        button {
            display: inline-block;
            padding: 14px 28px 15px;
            background-color: $dark2;
            color: rgba(255, 255, 255, 0.72);
            font-weight: 700;
            font-size: 17px;
            line-height: 1.294em;
            border-radius: 10px;
            min-width: 250px;
            margin: 0 10px;
            transition: color 0.35s ease, background 0.35s ease;

            &:hover{
                background-color: var(--colored3);
                color: $bright;
            }

            &.selected {
                background-color: var(--colored);
                color: $bright;
            }
        }
    }

    &_resultat {
        &_item {
            border-top: solid 1px rgba(255, 255, 255, 0.16);
            padding: 49px 0 50px;
            transition: padding 0.35s ease;
        }

        &_toggle {
            display: block;
            width: 100%;
            text-align: left;
            position: relative;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                width: 2px;
                height: 16px;
                background-color: $blue-medium;
                top: 1px;
                right: 9px;
                transition: transform 0.35s ease;
            }

            &:before {
                content: "";
                position: absolute;
                width: 16px;
                height: 2px;
                background-color: $blue-medium;
                top: 8px;
                right: 2px;
                transition: transform 0.35s ease;
            }
        }

        &_item:not(&_item--selected) &_toggle{
            &:hover{
                &:after{
                    transform: rotate(90deg);
                }
                &:before{
                    transform: rotate(90deg);
                }
            }
        }

        &_item--selected {
            padding: 71px 0 50px;
        }

        &_item--selected &_toggle {
            &:after {
                transform: rotate(-90deg);
            }
        }

        &_titre {
            color: $bright;
            font-weight: 600;
            font-size: 22px;
            line-height: 1.1818em;
            text-transform: uppercase;
            letter-spacing: 0.1em;
        }

        &_content {
            //max-height: 0;
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.35s ease-out,max-height 0.35s ease, opacity 0.15s ease, margin 0.35s ease 0.2s;
            opacity: 0;
            pointer-events: none;

            &_inner{
                overflow: hidden;
                display: flex;
            }
        }

        &_item--selected &_content {
            //max-height: 400px;
            opacity: 1;
            grid-template-rows: 1fr;
            transition: max-height 0.35s ease, opacity 0.15s ease 0.2s, margin 0.35s ease;
            pointer-events: all;
            margin-top: 32px;
            margin-bottom: 26px;
        }

        &_col {

            &_visuel {
                margin-right: 34px;
                width: 305px;
                flex: 0 0 305px;

                img {
                    border-radius: 8px;
                }
            }

            &_wysiwyg {

                h3 {
                    color: $bright;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 1.83333em;
                    margin-bottom: 7px;
                }

                ul, ol {
                    padding-top: 7px;
                    margin-bottom: 44px;
            
                    li {
                        list-style-type: disc;
                        margin-left: 22px;
                        padding-left: 1px;
                        color: $bright;
                        font-size: 16px;
                        line-height: 1.5em;
                        font-weight: 600;
                        margin-bottom: 16px;
            
                        strong {
                            font-weight: 700;
                        }
            
                        a {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 1.5em;
                        }
                    }
                }

                ol{
                    li{
                        list-style-type: decimal;
                    }
                }

                p {
                    color: $bright;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.625em;
                    margin-bottom: 16px;
                }

                a {
                    color: var(--colored);
                    font-style: italic;
                    text-decoration: underline;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1.625em;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include m1023 {
        margin-bottom: 85px;
        
        &_intro {
            padding-bottom: 60px;
        }

        &_titre {
            margin-bottom: 40px;
            letter-spacing: 0.015em;
        }

        &_item {
            display: block;
            margin-bottom: 16px;

            button {
                font-size: 14px;
                line-height: 1.642857em;
                padding: 8px 0px 7px;
                display: inline-block;
                width: 88%;
                max-width: 400px;
                min-width: 0;
            }
        }

        &_resultat {
            &_content{
                &_inner{
                    flex-direction: column;
                }
            }
            &_titre {
                font-size: 16px;
                line-height: 1.625em;
                max-width: 80%;
                text-align: left;
            }

            &_item{
                padding: 26px 0 22px;
            }

            &_item--selected {
                padding: 40px 0 44px;
            }

            &_content{
                flex-direction: column;
            }

            &_item--selected &_content {
                max-height: 200vh;
                margin-top: 46px;
            }

            &_col {

                &_visuel {
                    max-width: 100%;
                    margin-right: 0;
                    flex: 0 0;
                    margin-bottom: 61px;
                }
            }
        }
    }
}