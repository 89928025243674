.footer {
	color: $blue-medium;
	background-color: $blue-dark;
	text-align: center;
	font-size: 13px;
	font-weight: 700;
	padding: 0 0 4px;
	height: 140px;

	@include m1023 {
		height: 100%;
	}

	&_inner {
		border-top: solid 1px rgba(255, 255, 255, 0.16);
		padding: 10px 0;
	}

	&_logos,
	&_links {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	&_logos {
		padding-top: 5px;
		opacity: 0.5;

		li {
			margin: 0px 7px;

			img {
				height: 100%;
				width: auto;
				object-fit: contain;
			}
		}

		li:first-child {
			height: 40px;
		}

		li:last-child {
			height: 30px;
		}
	}

	&_links {
		padding: 14px 0;

		li {
			margin: 0px 12px;
			position: relative;

			&:after {
				content: "|";
				position: absolute;
				left: calc(100% + 10px);
			}

			&:last-child:after {
				display: none;
			}
		}
	}

	@include m1023 {
		&_inner {
			padding: 18px 0;
		}

		&_logos {
			padding-top: 0;
			li {
				margin: 0px 10px;
			}
		}

		&_links {
			padding: 11px 0;
		}
	}
}
