// Media queries
@mixin m2000 {
    @media screen and (min-width: 2000px) {
        @content;
    }
}

@mixin m1700 {
    @media screen and (min-width: 1700px) {
        @content;
    }
}

@mixin m1500 {
    @media screen and (min-width: 1500px) {
        @content;
    }
}

@mixin m1459 {
    @media screen and (max-width: 1459px) {
        @content;
    }
}

@mixin m1400 {
    @media screen and (min-width: 1400px) {
        @content;
    }
}

@mixin m1366 {
    @media screen and (min-width: 1366px) {
        @content;
    }
}

@mixin m1280 {
    @media screen and (min-width: 1280px) {
        @content;
    }
}

@mixin m1279 {
    @media screen and (max-width: 1279px) {
        @content;
    }
}

@mixin m1239 {
    @media screen and (max-width: 1239px) {
        @content;
    }
}

@mixin m1200-1279 {
    @media screen and (min-width: 1200px) and (max-width: 1279px) {
        @content;
    }
}

@mixin m1200 {
    @media screen and (min-width: 1200px) {
        @content;
    }
}

@mixin m1199 {
    @media screen and (max-width: 1199px) {
        @content;
    }
}

@mixin m1100-1199 {
    @media screen and (min-width: 1100px) and (max-width: 1199px) {
        @content;
    }
}

@mixin m1100-1365 {
    @media screen and (min-width: 1100px) and (max-width: 1365px) {
        @content;
    }
}

@mixin m1100 {
    @media screen and (min-width: 1100px) {
        @content;
    }
}

@mixin m1099 {
    @media screen and (max-width: 1099px) {
        @content;
    }
}

@mixin m1024 {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin m1023 {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@mixin m992-1199 {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin m992-1099 {
    @media screen and (min-width: 992px) and (max-width: 1099px) {
        @content;
    }
}

@mixin m992 {
    @media screen and (min-width: 992px) {
        @content;
    }
}

@mixin m991 {
    @media screen and (max-width: 991px) {
        @content;
    }
}

@mixin m768-1023 {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin m1024-1099 {
    @media screen and (min-width: 1023px) and (max-width: 1099px) {
        @content;
    }
}

@mixin m768-1099 {
    @media screen and (min-width: 768px) and (max-width: 1099px) {
        @content;
    }
}

@mixin m768-991 {
    @media screen and (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}

@mixin m768 {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin m767 {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin m479 {
    @media screen and (max-width: 479px) {
        @content;
    }
}

@mixin m375 {
    @media screen and (min-width: 375px) {
        @content;
    }
}

@mixin m376 {
    @media screen and (min-width: 376px) {
        @content;
    }
}

@mixin m349 {
    @media screen and (max-width: 349px) {
        @content;
    }
}

@mixin m399 {
    @media screen and (max-width: 399px) {
        @content;
    }
}

@mixin m1024-h560 {
    @media screen and (min-height: 560px) and (min-width: 1024px) {
        @content;
    }
}

@mixin m1024-h750 {
    @media screen and (min-height: 700px) and (min-width: 1024px) {
        @content;
    }
}

@mixin m1024-h850 {
    @media screen and (min-height: 850px) and (min-width: 1024px) {
        @content;
    }
}

@mixin m1024-h560-h720 {
    @media screen and (min-height: 560px) and (max-height: 720px) and (min-width: 1024px) {
        @content;
    }
}

@mixin m1300-h560-h720 {
    @media screen and (min-height: 560px) and (max-height: 720px) and (min-width: 1300px) {
        @content;
    }
}

// FONTS
@mixin light {
    font-family: $main-font;
    font-weight: 300;
}

@mixin regular {
    font-family: $main-font;
    font-weight: 400;
}

@mixin medium {
    font-family: $main-font;
    font-weight: 500;
}

@mixin semi-bold {
    font-family: $main-font;
    font-weight: 600;
}

@mixin bold {
    font-family: $main-font;
    font-weight: 700;
}

@mixin title {
    font-family: $font-title;
    font-weight: 700;
}

@mixin sub {
    font-family: $font-title;
    font-weight: 600;
}