.template-vr__wrapper {
    background: $blue-dark;
    color: $bright;
    font-family: $main-font;
    padding: 0px;
    min-height: 100vh;
    position: relative;

    @include m991 {
        padding: 0px 16px;
    }

    .header-template-vr {
        text-align: center;
        margin: 0px auto;
        padding: 10px;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 130px;
        transform: translateX(-50%);

        @include m991 {
            position: relative;
            top: 0;
        }

        .title {
            font-size: 46px;
            line-height: 1;
            margin-bottom: 10px;
            text-indent: -9999px;

            @include m991 {
                font-size: 22px;
                line-height: 1.36;
                margin-bottom: 0px;
            }
        }

        .subtitle {
            font-size: 28px;
            color: $bright;
            line-height: 1;

            @include m991 {
                color: $blue-medium;
                font-size: 18px;
                line-height: 1.33;
            }
        }
    }

    .template-vr-content__container {
        margin: 0 auto;
        padding: 35px 0px;
        text-align: left;

        @include m991 {
            padding: 22px 0px;
        }

        .template-vr-content-title__container {
            border-bottom: 1px solid rgba($color: $bright, $alpha: 0.16);
            padding-bottom: 35px;
            margin-bottom: 35px;
            position: static;

            @include m991 {
                padding-bottom: 16px;
                margin-bottom: 16px;
                position: initial;
            }

            .arrow-redirect {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 138px;
                left: 128px;
                z-index: 2;
                cursor: pointer;
                border-left: 2px solid $bright;
                border-top: 2px solid $bright;
                display: block;
                transform: translateY(-50%) rotate(-45deg);

                @include m991 {
                    top: 130px;
                    left: 60px;
                }
            }

            .template-vr-content-title-color {
                color: $pink;
                font-size: 16px;
                line-height: 1.19;
                font-weight: bold;
                text-align: center;
                margin-bottom: 10px;

                @include m991 {
                    text-align: left;
                    margin-bottom: 5px;
                }
            }

            .template-vr-content-title {
                font-size: 22px;
                text-align: center;
                line-height: 1.23;
                font-weight: bold;

                @include m1024 {
                    margin: 0 100px;
                }

                @include m991 {
                    line-height: 1;
                    text-align: left;
                }
            }
        }

        .template-vr-content-text {
            font-size: 18px;
            line-height: 1.44;
            font-weight: 600;
            margin-bottom: 35px;

            @include m991 {
                font-weight: 300;
                margin-bottom: 16px;
            }
        }
    }
}