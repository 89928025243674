.headerPageHome {
	text-align: center;
	background: linear-gradient(#006c8c, #00a6d9);
	color: $bright;
	height: calc(100vh - 140px);

	&_inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 5vh;
		padding-bottom: 9vh;
		height: 100%;
		justify-content: space-between;
	}

	&_logo {
		width: 175px;
		margin-bottom: 40px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&_logo2 {
		img {
			display: inline-block;
		}
	}

	&_cols {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&_col {
		text-align: center;
		position: relative;

		img {
			display: inline-block;
		}

		p {
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&Texte {
			font-size: 22px;

			@include m1024 {
				font-size: 16px;
			}
		}

		&Image {
			width: 236px;
			height: 50px;
		}

		&--1 {
			flex: 0 0 40%;
			padding-right: 6.3%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				margin-right: 21px;
			}
		}

		&--2 {
			flex: 0 0 50.3%;

			p {
				width: 412px;
				height: 75px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&:nth-child(1):after {
			position: absolute;
			content: "";
			width: 2px;
			background-color: $bright;
			top: -14px;
			bottom: -3px;
			right: 0;
		}
	}

	&_animation {
		padding-top: 5vh;
		padding-bottom: 5vh;
		width: 822px;
		height: 215px;
		box-sizing: content-box;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			transform: scale(1.75);
		}
	}

	@include m1023 {
		height: auto;

		&_inner {
			height: auto;
			padding-top: 28px;
			padding-bottom: 42px;
		}

		&_logo {
			width: 165px;
			margin-bottom: 32px;
		}

		&_cols {
			flex-direction: column;
		}

		&_col {
			p {
				margin-bottom: 2px;
			}

			&Texte {
				line-height: 27px;
				max-width: 90%;
				margin: 0;
				letter-spacing: -1px;
				font-size: 18px;
			}

			&Image {
				width: 173px;
				height: 62px;
				margin: 0 auto;
			}

			&--1 {
				display: flex;
				width: 100%;
				flex-direction: column;
				align-items: center;
				padding-right: 0;
			}

			&--2 {
				p {
					width: 90%;
					margin: 0 auto;
				}

				img {
					width: 298px;
					max-width: 87%;
				}
			}

			&:nth-child(1):after {
				width: 43px;
				height: 1px;
				position: relative;
				top: auto;
				bottom: auto;
				right: auto;
				display: block;
				margin: 31px auto 15px;
			}
		}

		&_animation {
			padding-bottom: 85px;
			padding-top: 60px;
			width: 100%;
			height: auto;

			img {
				object-fit: initial;
				width: 100%;
				height: auto;
				transform: scale(1);
			}
		}
	}
}
