.flex_intro {
    /*    padding-top: 24px;
        margin-bottom: 44px;*/
    padding-bottom: 32px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.16);
    position: static;

    &_back {
        position: absolute;
        top: 108px;
        left: 128px;
        z-index: 2;
    }

    &_theme {
        color: $pink;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 12px;
    }

    &_titre {
        color: $bright;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        margin: 0 35px 10px 35px;
    }

    &_infos {
        color: $blue-medium;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        justify-content: center;

        &_author {}

        &_position {
            position: relative;
            margin-left: 45px;

            &:before {
                content: "";
                width: 15px;
                height: 19px;
                background: transparent url('/assets/img/picto_location.svg') no-repeat center center;
                background-size: contain;
                position: absolute;
                left: -22px;
                top: 0px;
            }
        }

        &_time {
            position: relative;
            margin-left: 45px;

            &:before {
                content: "";
                width: 16px;
                height: 16px;
                background: transparent url('/assets/img/icon_time.png') no-repeat center center;
                background-size: contain;
                position: absolute;
                left: -22px;
                top: 0px;
            }
        }
    }

    @include m1023 {
        /* padding-top: 23px;

         margin-bottom: 24px;*/
        padding-bottom: 20px;
        position: static;

        &_back {
            top: 75px;
            left: 30px;
            z-index: 2;
        }

        &_theme {
            text-align: left;
            margin-bottom: 10px;
            max-width: calc(100% - 70px);
        }

        &_titre {
            text-align: left;
            line-height: 1.2em;
            margin: 0 0 4px;
        }

        &_infos {
            display: block;
            line-height: 1.7em;

            span {
                display: block;
            }

            &_position {
                margin-left: 24px;

                &:before {
                    top: 3px;
                }
            }

            &_time {
                position: absolute;
                top: 398px;
                right: 16px;

                &:before {
                    left: -25px;
                    top: 5px;
                }
            }
        }
    }
}