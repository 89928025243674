.overlay-tuto__container {
    position: relative;

    &.closed {
        display: none;
    }

    .overlay-tuto {
        position: fixed;
        background: rgba($color: $dark, $alpha: 0.85);
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 2;

        &-close {
            position: absolute;
            z-index: 5;
            height: 45px;
            width: 45px;
            left: 10%;
            top: 50px;
            border-radius: 50%;
            background-color: #474f52;
            cursor: pointer;

            @include m1199 {
                left: 40px;
            }

            @include m991 {
                height: 35px;
                width: 35px;
                right: 0px;
                top: 20px;
                left: 10px;
            }

            &:before,
            &:after {
                content: "";
                width: 60%;
                height: 2px;
                top: 50%;
                left: 50%;
                background: $bright;
                position: absolute;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

        }

        &-button {
            display: inline-block;
            width: 17px;
            height: 17px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: rotate(-45deg);

            @include m991 {
                width: 12px;
                height: 12px;
            }

            &Box {
                display: flex;
                align-items: center;
                width: 60px;
                justify-content: center;

                @include m991 {
                    width: 40px;
                }
            }

            &--next {
                transform: rotate(135deg);
            }

            &--hidden {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .tuto-steps {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        position: absolute;
        z-index: 999;
        transform: translateX(-50%);
        left: 50%;

        @include m991 {
            margin-top: 37px;
        }

        .step {
            border-radius: 50%;
            border: 2px solid $bright;
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 41px;
            font-weight: bold;
            line-height: 0.61;
            margin: 0 23px;
            cursor: pointer;
            position: relative;

            @include m991 {
                width: 35px;
                height: 35px;
                font-size: 18px;
                margin: 0 10px;
            }

            &:after {
                content: "";
                position: absolute;
                right: -28px;
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: $bright;
                border-radius: 50%;

                @include m991 {
                    width: 3px;
                    height: 3px;
                    right: -14px;
                }
            }

            &:nth-last-child(2):after {
                display: none;
            }

            &.active {
                color: $blue-dark;
                border: 2px solid $blue;
                background-color: $blue;
            }
        }
    }
}

.tuto-explication__container {
    display: none;
}

.active-step {
    position: relative;
    z-index: 2;
    pointer-events: none;

    .tuto-explication__container {
        font-size: 26px;
        font-weight: bold;
        line-height: 1;
        position: absolute;
        display: flex !important;
        align-items: flex-start;
        justify-content: flex-end;
        width: 90%;

        @include m991 {
            font-size: 16px;
            line-height: 1.13;
            top: calc(100% + 40px);
        }

        p {
            width: 70%;
            margin-left: 15px;

            @include m991 {
                margin-left: 10px;
            }
        }

        img {
            width: 25%;
            margin-top: -25px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: calc(100% + 35px);
        height: 104%;
        border: 2px solid $blue;
        border-radius: 15px;
        top: -2%;
        left: -10px;
        z-index: -1;
        margin-left: -8px;

        @include m991 {
            width: calc(100% + 16px);
            left: 0;
        }
    }
}