.button {
    font-size: 14px;
    font-weight: 700;
    background-color: $bright;
    color: $blue-dark;
    border-radius: 40px;
    padding: 12px 30px 12px;
    min-width: 288px;
    display: inline-block;
    text-align: center;

    @include m1023 {
        min-width: 200px;
        padding: 16px 40px 15px;
    }

    &--type1 {
        &:hover {
            background-color: rgba(255, 255, 255, .8);
        }
    }

    &--type2 {
        background-color: transparent;
        border: solid 2px $bright;
        color: $bright;
        padding: 13px 41px 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 288px;

        &:after {
            content: url("/assets/img/icon_arrow.svg");
            margin-left: 10px;
            transform: translateY(2px);

            &:hover {
                content: url("/assets/img/icon_arrow_pink.svg");
            }
        }

        &--noArrow {
            &:after {
                display: none;
            }
        }

        &:hover {
            background-color: $bright;
            color: $pink;
        }

        @include m1023 {
            min-width: 180px;
            padding: 13px 21px 13px;
        }
    }

    &--type3 {
        min-width: 0px;
        display: flex;
        align-items: center;
        padding: 15px 28px 15px 26px;

        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border: solid 8px transparent;
            border-left: solid 12px $blue-dark;
        }
    }

    &--type4 {
        background-color: transparent;
        border: solid 2px #ffffff;
        color: #ffffff;
        padding: 13px 0 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 170px;
    }

    &--type5 {
        display: inline-block;
        cursor: pointer;
        padding: 19px 0 21px;
        margin: 0 19px;
        font-size: 18px;
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.14);
        color: $bright;
        border-radius: 9px;
        width: 288px;

        &:hover {
            background-color: $bright;
            color: $blue-dark;
        }

        @include m1023 {
            margin: 0 auto 18px;
            max-width: 85vw;
            display: block;
        }
    }


    &--back {
        display: inline-block;
        width: 17px;
        height: 17px;
        border-top: solid 2px $bright;
        border-left: solid 2px $bright;
        transform: rotate(-45deg);
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}