.flex_playlist {
    @include playlist;
    border-bottom: none;
    //margin-top: 47px;

    &_titre {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 30px;
    }

    &_contenus {
        &_item {
            td:first-child {
                display: none;
            }

            td:nth-child(2) {
                height: 115px;
            }

            &Label {
                strong {
                    display: block;
                }
            }
        }

        padding-bottom: 48px;
        margin-bottom: 55px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.16);
    }

    @include m1023 {
        //margin-top: 82px;

        &_titre {
            margin-bottom: 3px;
        }

        &_contenus {
            //padding-bottom: 47px;
            padding-bottom: 30px;
            margin-bottom: 35px;

            tbody {
                tr {
                    td:nth-child(2) {
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
}