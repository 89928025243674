.headerPageFlex {
	text-align: center;
	color: $bright;
	height: 625px;
	position: relative;

	&_inner {
		height: 100%;
	}

	&_logo {
		width: 186px;
		height: 57px;
		margin: 0 auto;
		transform: translateY(55px);

		img {
			width: 100%;
			height: 100%;
			max-width: none;
			object-fit: contain;
		}
	}

	&_soundInterface {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@include m1023 {
		height: 380px;

		&_logo {
			display: none;
		}
	}
}
