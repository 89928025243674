.soundPlayerIntro{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__micro{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent url("/assets/img/icon_micro.svg") no-repeat center 43%;
        background-size: 27%;
    }

    &__timer{
        position: absolute;
        bottom: 23px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }
}