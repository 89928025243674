.flex_cta:not(.flex_cta--360),
.flex_intro,
.flex_playlist,
.flex_slideshow,
.flex_texte,
.flex_titre,
.flex_video,
.flex_visuel {

    &:first-child {
        padding-top: 40px;
        @include m1023 {
            padding-top: 25px;
        }
    }
    &:not(:first-child) {
        margin-top: 110px;
        @include m1023 {
            margin-top: 70px;
        }

        /*
        ------flex-cta
        margin-top: 94px;

         ------flex-intro
         padding-top: 24px;
         padding-bottom: 32px;
         margin-bottom: 44px;

        ------flex-playlist
         margin-top: 47px;

        ------flex-slideshow
         margin-top: 60px;
         padding-bottom: 34px;

        ------flex-texte
         margin-top: 44px;

        ------flex-titre
         margin-bottom: 20px;
         margin-top: 20px;

       ------flex-video
         margin-top: 84px;

        ------flex-visuel
         margin-top: 39px;*/

        @include m1023 {
            /*
            ------flex-cta
            margin-top: 48px;

            ------flex-intro
            padding-top: 23px;
            padding-bottom: 20px;
            margin-bottom: 24px;

            ------flex-playlist
            margin-top: 82px;

            ------flex-slideshow
            margin-top: 26px;
            padding-bottom: 18px;

            ------flex-texte
            margin-top: 18px;

            ------flex-titre
            margin-bottom: 3px;
            margin-top: 3px;

            ------flex-visuel
            margin-top: 25px;*/
        }

    }
}

.flex_intro + .flex_texte {
    &:not(:first-child) {
        margin-top: 32px;
        @include m1023 {
            margin-top: 20px;
        }
    }
}

.flex_video + .flex_texte,
.flex_slideshow + .flex_texte {
    &:not(:first-child) {
        margin-top: 58px;
        @include m1023 {
            margin-top: 58px;
        }
    }
}

.flex_titre + div[class*='flex_'] {
    &:not(:first-child) {
        margin-top: 40px;
        @include m1023 {
            margin-top: 25px;
        }
    }
}