.wysiwyg {
    border-top: solid 1px rgba(255, 255, 255, 0.16);
    border-bottom: solid 1px rgba(255, 255, 255, 0.16);
    padding: 97px 0;

    &_container {
        display: flex;
    }

    &_visuel {
        margin-right: 116px;
        width: 340px;
        flex: 0 0 340px;

        img {
            border-radius: 20px;
        }
    }

    h3 {
        color: $bright;
        font-weight: 600;
        font-size: 22px;
        line-height: 1.181818em;
        margin-bottom: 44px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding-top: 41px;
    }

    h4 {
        color: $bright;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.833333em;
        margin-bottom: 8px;
        letter-spacing: -0.01em;
    }

    p {
        color: $bright;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.833333em;
        margin-bottom: 44px;
    }

    a {
        color: var(--colored);
        font-style: italic;
        text-decoration: underline;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.833333em;

        &:hover {
            text-decoration: none;
        }

        &.btn_edito {
            display: inline-block;
            padding: 9px 28px 8px;
            background-color: var(--colored);
            font-style: normal;
            color: $bright;
            font-weight: 700;
            font-size: 17px;
            line-height: 1.294em;
            border-radius: 5px;
            transition: color 0.35s ease, background 0.35s ease;
            text-decoration: none;

            &:hover,
            &:focus {
                background-color: var(--colored2);
                color: rgba(255, 255, 255, 0.72);
            }
        }
    }

    ul,
    ol {
        padding-top: 7px;
        margin-bottom: 44px;

        li {
            list-style-type: disc;
            margin-left: 22px;
            padding-left: 1px;
            color: $bright;
            font-size: 16px;
            line-height: 1.5em;
            font-weight: 600;
            margin-bottom: 16px;

            strong {
                font-weight: 700;
            }

            a {
                font-weight: 700;
                font-size: 16px;
                line-height: 1.5em;
            }
        }
    }

    ol {
        li {
            list-style-type: decimal;
        }
    }

    &_blockquote {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    blockquote {
        color: $bright;
        font-weight: 400;
        font-size: 29px;
        line-height: 1.4483em;
        text-decoration: underline;
        text-decoration-color: var(--colored);
        text-underline-offset: 10px;
        margin-bottom: 16px;
        letter-spacing: 0.01em;

        &+h4 {
            margin-bottom: 0px;
        }
    }

    &_content {
        &_image {

            img {
                width: 100%;
            }

            &.truesize {
                text-align: center;

                img {
                    width: auto;
                    display: inline-block;
                }
            }
        }

        &_legende {
            font-size: 14px;
            line-height: 1.714em;
            color: $bright;
            display: block;
            padding-top: 3px;
            margin-bottom: -27px;
        }

        &_video {
            position: relative;
            margin-bottom: 44px;

            &_visuel {
                width: 100%;
            }

            &_content {
                width: 100%;
                cursor: pointer;

                &:after {
                    content: "";
                    position: absolute;
                    pointer-events: none;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    background: linear-gradient(transparent, black);
                    transition: opacity 0.35s ease;
                    opacity: 0.65;
                    z-index: 1;
                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border: solid 17px transparent;
                    border-left: solid 30px $bright;
                    left: 33px;
                    bottom: 37px;
                    transition: border 0.35s ease;
                    z-index: 10;
                }

                &:hover,
                &:focus {
                    &:after {
                        opacity: 1;
                    }
                }

                &:hover,
                &:focus {
                    &:before {
                        border-left-color: var(--colored);
                    }
                }
            }

            &_infos {
                position: absolute;
                bottom: 28px;
                left: 108px;
                text-align: left;
            }

            &_titre {
                text-transform: uppercase !important;
                color: $bright !important;
                font-size: 14px !important;
                font-weight: 700 !important;
                line-height: 1.5714em !important;
                margin-bottom: 0px !important;
                position: relative;
                z-index: 10;
            }

            &_description {
                color: $bright !important;
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 1.5714em !important;
                margin-bottom: 0px !important;
                position: relative;
                z-index: 10;
            }
        }

        &_iframe {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%;
            height: 0;

            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }

    @include m1023 {
        padding-top: 85px;
        padding-bottom: 49px;

        &_container {
            flex-direction: column;
        }

        &_visuel {
            max-width: 100%;
            flex: 0 0;
            margin-bottom: 36px;

            img {
                border-radius: 10px;
            }
        }

        &_content {
            &_video {
                margin-bottom: 32px;

                &_infos {
                    left: auto;
                    right: auto;
                    top: auto;
                    bottom: auto;
                    position: relative;
                    margin-top: 30px;
                }

                &_content {
                    &:before {
                        top: 37.8vw;
                        bottom: auto;
                        left: 15px;
                        border: solid 11px transparent;
                        border-left: solid 20px $bright;
                    }

                    &:after {
                        display: none;
                    }
                }

                &_visuel {
                    width: calc(100vw - 40px);
                    height: calc(56.25vw - 22.5px);
                    object-fit: cover;

                    &Box {
                        position: relative;
                        margin-bottom: 30px;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background: linear-gradient(transparent, black);
                            pointer-events: none;
                        }
                    }
                }
            }

            &_legende {
                padding-top: 31px;
                margin-bottom: -2px;
            }
        }

        h3 {
            font-size: 20px;
            line-height: 1.3em;
            margin-bottom: 31px;
            letter-spacing: 0.1em;
            padding-top: 41px;
        }

        h4 {
            font-size: 18px;
            line-height: 1.833333em;
            margin-bottom: 8px;
            letter-spacing: 0em;
        }

        p {
            font-size: 16px;
            line-height: 1.625em;
            margin-bottom: 32px;
        }

        blockquote {
            font-weight: 600;
            font-size: 25px;
            line-height: 1.48em;
            text-underline-offset: 10px;
            margin-bottom: 22px;
            letter-spacing: 0.0em;
            padding-top: 40px;
        }

        &_blockquote {
            margin-top: 50px;
            margin-bottom: 71px;

            h4 {
                font-weight: 700;
                font-size: 14px;
                line-height: 1.714em;
            }

            p {
                font-size: 14px;
                line-height: 1.714em;
            }
        }
    }
}

.videoWysiwyg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;

    &_content {
        position: relative;
    }

    &_close {
        color: $bright;
        position: absolute;
        top: -30px;
        right: 0;
        transition: transform 0.35s ease;
        transform-origin: 20px 10px;
        width: 30px;
        height: 30px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 2px;
            width: 23px;
            background-color: $bright;
            left: 8px;
        }

        &:before {
            top: 3px;
            transform: translateY(5px) rotate(45deg);
        }

        &:after {
            top: 13px;
            transform: translateY(-5px) rotate(-45deg);
        }

        &:hover {
            transform: rotate(90deg);
        }

        @media (min-aspect-ratio: 93 / 56) {
            right: -100px;
        }
    }

    &_video {
        @media (max-aspect-ratio: 93 / 56) {
            width: 90vw;
            height: 50.625vw;
        }

        @media (min-aspect-ratio: 93 / 56) {
            height: 75vh;
            width: 133.33333vh;
        }
    }

    &_infos {
        position: absolute;
        left: 0;
        right: 0;
        top: 102%;
    }

    &_titre {
        text-transform: uppercase !important;
        color: $bright !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 1.5714em !important;
        margin-bottom: 0px !important;
        position: relative;
        z-index: 10;
    }

    &_description {
        color: $bright !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 1.5714em !important;
        margin-bottom: 0px !important;
        position: relative;
        z-index: 10;

        br {
            display: none;
        }
    }
}