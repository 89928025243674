.flex_cta {
    text-align: center;
    // margin-top: 94px;

    @include m1023 {
        //margin-top: 48px;
    }

    &--360 {
        position: sticky;
        height: 200px;
        padding-top: 120px;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(43, 96, 119, 0), #15252c);
        pointer-events: none;

        a {
            box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.5);
            pointer-events: all;
        }

        @include m1023 {
            bottom: -30px;
            font-size: 17px;
            padding-top: 80px;
            margin-top: 0;
        }
    }
}