.radios {
    label {
        font-size: 13px;
        font-weight: 500;
    }

    input[type=radio] {
        display: none;
    }

    input[type=radio]+label {
        display: inline-block;
        cursor: pointer;
        padding: 19px 0 21px;
        margin: 0 19px;
        font-size: 18px;
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.14);
        color: $bright;
        border-radius: 9px;
        width: 316px;
    }

    input[type=radio]:checked+label, input[type=radio]:hover+label{
        background-color: $bright;
        color: $blue-dark;
    }

    @include m1023 {
        input[type=radio]+label {
            margin: 0 auto 18px;
            max-width: 85vw;
            display: block;
        }
    }
}