.map-campus {
    color: $bright;
    font-family: $main-font;
    font-size: 30px;
    font-weight: 800;
    line-height: 1.37;
    @include m991 {
        font-size: 24px;
        line-height: 1.33;
        padding: 0px 16px;
    }
    .tuto-explication__container {
        display: none;
        top: calc(100% + 80px);
        left: 50%;
        transform: translateX(-50%);
        max-width: 450px;
    }
    &.active-step {
        &:after {
            background: $blue-medium2;
        }
    }

    &-visual {
        padding-bottom: 15px;
        h2 {
            @include m991 {
                margin-bottom: 20px;
            }
        }
        &__container {
            position: relative;
            max-width: 750px;
            margin: 0 auto;
            @include m991 {
                max-width: 450px;
            }
            img {
                height: auto;
                width: 100%;
            }
            .hotspot {
                position: absolute;
                transform: translate(-50%, -50%);
                display: none;
                &.spot {
                    display: block;
                    &-1 {
                        top: 64.5%;
                        left: 8%;
                    }
                    &-2 {
                        top: 48%;
                        left: 27%;
                    }
                    &-3 {
                        top: 42%;
                        left: 34%;
                    }
                    &-4 {
                        top: 51%;
                        left: 71%;
                    }
                    &-5 {
                        top: 55.5%;
                        left: 76.7%;
                    }
                    &-6 {
                        top: 61.5%;
                        left: 84.5%;
                    }
                }
                &-icon {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 5px solid white;
                    @include m991 {
                        width: 18px;
                        height: 18px;
                        border: 4px solid white;
                    }
                }
                &-tooltip {
                    position: absolute;
                    color: $blue-dark;
                    font-size: 17px;
                    line-height: 1;
                    background-color: white;
                    border-radius: 7px;
                    padding: 8px 14px;
                    white-space: nowrap;
                    bottom: 90px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include m991 {
                        bottom: 70px;
                        font-size: 15px;
                        padding: 5px 10px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        height: 90px;
                        width: 5px;
                        background-color: white;
                        bottom: -86px;
                        transform: translateX(-50%);
                        left: 50%;
                        @include m991 {
                            width: 4px;
                            height: 70px;
                            bottom: -67px;
                        }
                    }
                }
            }
        }
    }

    .input__container {
        text-align: left;
        margin: 30px auto;
        position: relative;
        @include m991 {
            margin: 20px auto 28px;
        }

        .input {
            border-radius: 5px;
            background-color: $bright;
            width: 100%;
            text-align: center;
            font-size: 23px;
            color: $blue-dark;
            padding: 12px 15px;

            @include m991 {
                font-size: 20px;
                text-align: left;
                margin-top: 7px;
                padding: 15px 15px 13px;
                &-submit {
                    height: 68px;
                }
            }

            &::placeholder {
                color: $blue-dark;
            }

            &.error::placeholder {
                color: $pink;
            }

            &:focus::placeholder,
            &:focus::placeholder {
                color: transparent;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }

            &-submit {
                position: absolute;
                width: 9%;
                min-width: 70px;
                height: 55px;
                right: 0;
                color: $blue-medium2;
                line-height: 1;

                @include m991 {
                    height: 70px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    height: 60%;
                    width: 1px;
                    background-color: $blue-medium2;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .checkmark {
                    display: block;
                    position: relative;
                    width: 40px;
                    margin: auto;

                    &::before,
                    &::after {
                        content: '';
                        height: 3px;
                        background-color: $blue-medium2;
                        position: absolute;
                    }

                    &::before {
                        width: 10px;
                        left: 15px;
                        top: 4px;
                        transform: translate(-50%, -50%) rotate(50deg);
                    }

                    &::after {
                        width: 20px;
                        left: 23px;
                        top: 0px;
                        transform: translate(-50%, -50%) rotate(-50deg);
                    }
                }
            }
        }
    }
}