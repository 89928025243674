@import "../../Styles/useful/mixins.scss";

.soundInterface {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	&_visuel {
		width: 575px;
		height: 370px;
		background-position: center center;
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: 85% auto;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 130px;
	}

	&_ui {
		display: flex;
		align-items: center;
		height: 140px;
		width: 810px;
	}

	&_box {
		flex: 0 0 20%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child {
			margin-left: 20%;
		}
	}

	&_button {
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center center;

		&--disabled {
			background-color: rgba(0, 0, 0, 0.35);
		}

		&--prev {
			width: 58px;
			height: 58px;
			background-image: url(/assets/img/bouton_next.svg);
			transform: rotate(180deg);
		}

		&--pause {
			width: 76px;
			height: 76px;
			background-image: url(/assets/img/bouton_play.svg);
			background-position: calc(50% + 2px) center;
		}

		&--play {
			width: 76px;
			height: 76px;
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 25px;
				bottom: 25px;
				width: 4px;
				height: auto;
				border-radius: 2px;
				background-color: #fff;
			}

			&:before {
				left: 30px;
			}

			&:after {
				right: 30px;
			}
		}

		&--next {
			width: 58px;
			height: 58px;
			background-image: url(/assets/img/bouton_next.svg);
		}

		&--disabled {
			opacity: 0.25;
			pointer-events: none;
		}
	}

	&_button2 {
		width: 44px;
		height: 44px;
		position: relative;
		border-radius: 50%;
		margin-right: 44px;

		&--current {
			background-color: #fff;
		}

		span {
			position: absolute;
			height: 2px;
			right: 12px;
			left: 12px;
			background-color: #fff;
		}

		&_top {
			top: 14px;
		}

		&_middle {
			top: 22px;
		}

		&_bottom {
			top: 30px;
		}
	}

	&_srt {
		position: absolute;
		left: 100px;
		right: 100px;
		top: 205px;
		bottom: 150px;
		text-align: center;
		pointer-events: none;

		p {
			margin-bottom: 18px;
		}

		&_line {
			display: none;
			font-size: 25px;
			font-weight: 400;
			margin-bottom: 18px;

			&--diff0,
			&--diff1,
			&--diff2 {
				display: block;
			}

			&--diff2 {
				opacity: 0.5;
			}

			&--diff1 {
				opacity: 0.7;
			}

			&--diff0 {
				font-size: 34px;
				font-weight: 400;
				opacity: 1;
			}
		}
	}

	&_progressBar{
		width: 100%;
		height: 5px;
		background-color: rgba(255, 255, 255, 0.3);
		position: relative;

		span{
			background-color: #fff;
			height: 5px;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	@include m1023 {
		&_visuel {
			width: 100%;
			height: 221px;
			top: 62px;
			background-size: 86% auto;

			.headerPageCollection_soundInterface & {
				height: 131px;
				top: 165px;
				background-size: contain;
			}
		}

		&_ui {
			width: 320px;
			height: 107px;
		}

		&_box {
			&:nth-child(1) {
				flex: 0 0 39.6875%;
				margin-left: 0;
			}

			&:nth-child(2) {
				flex: 0 0 20.625%;
			}

			&:nth-child(3) {
				flex: 0 0 39.6875%;
				margin-right: -10.6%;
			}

			&:nth-child(4) {
				flex: 0 0 19.84375%;
			}
		}

		&_button {
			&--prev {
				width: 46px;
				height: 46px;
			}

			&--play {
				width: 66px;
				height: 66px;

				&:before,
				&:after {
					top: 23px;
					bottom: 23px;
					width: 4px;
				}

				&:before {
					left: 26px;
				}

				&:after {
					right: 26px;
				}
			}

			&--pause {
				width: 66px;
				height: 66px;
			}

			&--next {
				width: 46px;
				height: 46px;
			}
		}

		&_button2 {
			margin-right: 0;

			&_top {
				top: 16px;
			}

			&_middle {
				top: 21px;
			}

			&_bottom {
				top: 26px;
			}
		}

		&_srt {
			top: 95px;
			bottom: 150px;
			left: 20px;
			right: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.headerPageCollection_soundInterface & {
				top: 210px;
			}

			&_line,
			p {
				font-size: 18px;
				line-height: 1em;
				margin-bottom: 9px;

				&--diff0 {
					font-size: 22px;
				}

				&--diff1 {
					opacity: 0.5;
					font-size: 14px !important;

					.headerPageCollection_soundInterface & {
						display: none;
					}
				}

				&--diff2 {
					display: none;
				}
			}
		}
	}

	@include m349 {
		&_ui {
			transform: scale(0.8);
		}
	}
}
