.templateAccordeon,
.templateHub,
.templateEdito {
    background: $blue-dark url("/assets/img/frise_template.png") no-repeat bottom 50px center;
    background-size: 100% auto;
    padding-bottom: 22.8vw;

    --colored: #{$blue};
    --colored2: #{$blue2};
    --colored3: #{$blue3};

    &.pink {
        background-image: url("/assets/img/frise_template_rouge.png");
        --colored: #{$pink};
        --colored2: #{$pink2};
        --colored3: #{$pink3};
    }

    @include m1023{
        padding-bottom: 27.7vw;
        background-size: 200% auto;
    }
}

html:has(.templateAccordeon){
    scroll-behavior: smooth;
}