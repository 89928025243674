.actualites-card{
    display: flex;
    height: fit-content;
    border-radius: 9px;
    background-color: rgba($dark, 0.15);
    padding: 16px;
    position: relative;
    margin-bottom: 65px;

    @include m991{
        flex-direction: column;
        padding: 0;
        margin-bottom: 50px;
    }

    &__image{
        min-width: 350px;
        background-color: #fff;
        border-radius: 9px;
        margin-right: 16px;
        overflow: hidden;

        @include m991{
            min-width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }

        img{
            min-width: 100%;
            object-fit: contain;
            max-width: 350px;
        }
    }
    &__content{
        margin: 25px 35px 0px;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include m991{
            padding: 0 16px;
            margin: 0;
        }

        &-titlesCtn{
            font-size: 26px;
            line-height: 1;
            font-weight: 800;
            margin-bottom: 15px;
            @include m991{
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
        &-subtitle{
            color: $blue-medium;
        }
        &-text{
            font-size: 17px;
            margin-bottom: 20px;
            max-width: 420px;
        }

        &-date{
            bottom: 16px;
            right: 16px;
            font-size: 14px;
            color: $blue-medium;
            margin-right: -35px;
            margin-top: auto;
            text-align: end;

            @include m991{
                position: relative;
                text-align: end;
                margin-top: 20px;
                margin-right: -16px;
            }
        }
    }
}