.flex_titre {
    font-size: 24px;
    font-weight: 900;
    color: $bright;
    //margin-bottom: 20px;
    //margin-top: 20px;
    //
    //@include m1023 {
    //  margin-bottom: 3px;
    //  margin-top: 3px;
    //}
}