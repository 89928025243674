.layout {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1018px;

  @include m768 {
    padding: 0 40px;
  }

  @include m1100 {
    padding: 0 0px;
  }

  &_inner {
    @include m1100 {
      padding: 0 100px;
    }
  }
}

.layout2 {
  margin: 0 auto;
  padding: 0 32px;
  width: 822px;

  @include m1023 {
    width: auto;
    padding: 0;
  }
}

.layout3 {
  margin: 0 auto;
  width: 90%;
  max-width: 1060px;

  @include m1023 {
    width: 88%;
    max-width: 1060px;
  }
}

.layout4 {
  margin: 0 auto;
  width: 90%;
  max-width: 1264px;

  @include m1023 {
    width: 100%;

    &>* {
      width: 87%;
      max-width: 1264px;
    }
  }
}

.layout5 {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 985px;

  @include m768 {
    padding: 0 40px;
  }

  @include m1100 {
    padding: 0 0px;
  }

  &_inner {
    @include m1100 {
      padding: 0 100px;
    }
  }
}


.layout6 {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1215px;

  @include m768 {
    padding: 0 40px;
  }

  @include m1100 {
    padding: 0 0px;
  }

  &_inner {
    @include m1100 {
      padding: 0 100px;
    }
  }
}

.layout7 {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1280px;

  @include m768 {
    padding: 0 40px;
  }

  @include m1100 {
    padding: 0 0px;
  }

  &_inner {
    @include m1100 {
      padding: 0 100px;
    }
  }
}

.layout8 {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 756px;

  @include m768 {
    padding: 0 40px;
  }

  @include m1100 {
    padding: 0 0px;
  }

  &_inner {
    @include m1100 {
      padding: 0 100px;
    }
  }
}


.scrollLocked {
  @include m1099 {
    position: relative;
    overflow: hidden;
  }
}

html {
  overflow-x: hidden;
}