.flex_texte {
    color: $bright;
    //margin-top: 44px;

    //.flex_video+& {
    //    margin-top: 56px;
    //}

    &_withTitle {
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 20px;
    }

    &_more {
        color: #06D1FF;
        font-size: 18px;
        margin-top: 15px;
    }

    @include m1023 {
        //margin-top: 18px;

        //.flex_video+& {
        //    margin-top: 61px;
        //}

        &_withTitle {
          margin-bottom: 3px;
        }
    }
}