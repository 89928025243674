.c-collection-cards {
    &-list {
        position: relative;
        padding-bottom: 50px;
        
        @include m991 {
            padding-bottom: 40px;
            .swiper {
                overflow: visible;
                position: relative;
                &::after {
                    content: "";
                    z-index: 2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    background: linear-gradient(90deg, rgba(21,37,44,0) 0%, rgba(21,37,44,1) 80%);
                    width: 25%;
                    height: 100%;
                    right: -50px;
                    pointer-events: none;
                }
            }
        }
        .swiper-button {
            position: absolute;
            cursor: pointer;
            top: 35%;
            transform: translate(-50%, -50%);
            @include m991 {
                display: none;
            }
            &.swiper-button-disabled {
                display: none;
            }
            &:after {
                content:'';
                display: inline-block;
                width: 20px; 
                height: 20px;
                border-radius: 2px;
                transform: rotate(-45deg);
            }
            &.prev {
                left: -20px;
                &:after {
                    border-left: 2px solid;
                    border-top: 2px solid;
                }
            }
            &.next {
                right: -35px;
                &:after {
                    border-right: 2px solid;
                    border-bottom: 2px solid;
                }
            }
        }
    }
    &-title {
        font-size: 30px;
        font-weight: 800;
        line-height: 1.37;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        @include m991 {
            font-size: 22px;
            line-height: 1.2;
            align-items: flex-start;
            flex-direction: column;
        }
        @include m767 {
            font-size: 18px;
        }
        &:hover {
            span:after {
                margin-left: 10px;
                transition: margin 0.2s linear;
            }
        }
        span {
            display: flex;
            align-items: center;
            &:after {
                content:'';
                display: inline-block;
                border-right: 4px solid;
                border-bottom: 4px solid;
                width: 15px; 
                height: 15px;
                border-radius: 2px;
                margin-left: 5px;
                transition: margin 0.2s linear;
                margin-top: 4px;
                transform: rotate(-45deg);
                @include m991 {
                    width: 12px; 
                    height: 12px;
                    margin-top: 0px;
                    margin-left: 3px;
                    border-right: 3px solid;
                    border-bottom: 3px solid;
                }
               
            }
        }
    }
    &-img {
        border-radius: 7px;
        display: flex;
        align-items: center;
        img {
            margin: 0 auto;
            aspect-ratio: 1/1;
            box-sizing: content-box;
            height: auto;
            object-fit: cover;
        }
    }
    &-duration {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.36;
        margin-top: 12px;
        display: flex;
        align-items: center;
        img {
            width: 15px;
            height: auto;
            margin-right: 6px;
        }
    }
    &-text {
        font-size: 17px;
        font-weight: normal;
        line-height: 1.12;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

}