.headerPageIntro {
    text-align: center;
    background: linear-gradient(#8F003C, #DC0059);
    color: $bright;
    height: calc(100vh - 140px);

    &_inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 10.7vh;
        padding-bottom: 9.6vh;
    }

    &_logo {
        position: relative;
        width: 292px;
        height: 89px;

        img {
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &_soundPlayer {
        position: absolute;
        left: calc(100% + 101px);
        top: 51%;
        transform: translateY(-50%);
        width: 150px;
        height: 150px;
    }

    &_animation {
        width: calc(100% + 64px);
        height: 49.63vh;
        max-height: 536px;
        text-align: center;
        box-sizing: content-box;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-width: none;
            display: inline;
            transform: scale(2.5);
            pointer-events: none;
        }

    }

    &_button {
        display: flex;
        justify-content: center;

        &>* {
            margin: 0 20px;
        }

        &>.button--type2{
            margin-left: 104px;
        }
    }

    &_srt {

        &_box {
            flex: 0 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
                margin-left: 20%;
            }
        }

        &_button2 {
            width: 44px;
            height: 44px;
            position: relative;
            border-radius: 50%;

            span {
                position: absolute;
                height: 2px;
                right: 12px;
                left: 12px;
                background-color: #fff;
            }

            &--current {
                background-color: #fff;

                span {
                    background-color: #DC0059;
                }
            }

            &_top {
                top: 14px;
            }

            &_middle {
                top: 22px;
            }

            &_bottom {
                top: 30px;
            }
        }

        &_srt {
            // position: absolute;
            // left: 0;
            // right: 0;
            // top: 258px;
            // bottom: 150px;
            text-align: center;
            pointer-events: none;
            width: calc(100% + 64px);
            height: 49.63vh;
            max-height: 536px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                margin-bottom: 22px;
            }

            &_line {
                display: none;
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 18px;

                &--diff0,
                &--diff1 {
                    display: block;
                }

                &--diff2 {
                    opacity: 0.4;
                }

                &--diff1 {
                    opacity: 0.7;
                }

                &--diff0 {
                    font-size: 28px;
                    font-weight: 400;
                    opacity: 1;
                }
            }
        }
    }

    @include m1023 {
        height: auto;

        &_inner {
            height: auto;
            padding-top: 47px;
            padding-bottom: 42px;
        }

        &_logo {
            height: auto;

            img {
                width: 154px;
            }
        }

        &_button {
            &>* {
                margin: 0 10px;
            }
            &>.button--type2{
                margin-left: 74px;
            }
        }

        &_soundPlayer {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            margin-top: 88px;
            width: 178px;
            height: 178px;
            margin-left: auto;
            margin-right: auto;
        }

        &_animation {
            width: 100%;
            height: auto;
            max-height: none;
            margin-top: 80px;
            margin-bottom: 57px;

            img {
                height: auto;
                width: 100%;
                object-fit: initial;
                transform: scale(1.5);
            }
        }

        &_srt {
            &_button2 {
                margin-right: 0;

                &_top {
                    top: 16px;
                }

                &_middle {
                    top: 21px;
                }

                &_bottom {
                    top: 26px;
                }
            }

            &_srt {
                width: 90%;
                padding-top: 0px;

                &_line,
                p {
                    font-size: 20px;
                    margin-bottom: 12px;

                    &--diff0 {
                        opacity: 1;
                        font-size: 5vw !important;
                    }

                    &--diff1 {
                        opacity: 0.5;
                        font-size: 4.375vw !important;
                    }

                    &--diff2 {
                        opacity: 0.25;
                        font-size: 4.375vw !important;
                    }

                    // &--diff2 {
                    //     display: none;
                    // }
                }
            }
        }
    }
}