.ariane{
    background-color: $blue-dark;
    border-top: solid 1px rgba(255, 255, 255, 0.16);
    padding: 5px 0;

    &_item{
        color: $bright;
        display: inline-block;
        margin-right: 15px;

        a, span{
            font-weight: 500;
            font-size: 14px;
            line-height: 1.8571425em;
        }

        a{
            color: $blue-medium;
            letter-spacing: 0.06em;
        }

        span{
            color: $bright;
            letter-spacing: 0.03em;
        }
    }
}