.headerPage404 {
    text-align: center;
    background: linear-gradient(#15252c, #889094);
    color: $bright;
    height: calc(100vh - 184px);

    &_inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 6vh;
        padding-bottom: 17vh;
    }

    &_logo {
        img {
            display: inline-block;
        }
    }

    &_title {
        font-size: 120px;
        margin-bottom: -7px;
        font-weight: 700;
    }

    &_subtitle {
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 13px;
    }

    &_texte {
        font-size: 18px;
        font-weight: 400;
        width: 414px;
        max-width: 100%;
        line-height: 26px;
    }

    @include m1023 {
        height: calc(100vh - 157px);

        &_inner {
            height: 100%;
            padding-top: 48px;
            justify-content: space-between;
            padding-bottom: 0;
        }

        &_logo {
            width: 152px;
        }

        &_other {
            flex: 1 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &_title {
            font-size: 80px;
            margin-bottom: 0px;
        }

        &_subtitle {
            font-size: 28px;
            margin-bottom: 12px;
        }

        &_texte {
            font-size: 18px;
            width: 244px;
            margin: 0 auto;
            line-height: 19px;
        }
    }
}