@font-face {
  font-family: 'icomoon';
  src: url('icomoon.ttf?g6x313') format('truetype'),
  url('icomoon.woff?g6x313') format('woff'),
  url('icomoon.svg?g6x313#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-address:before {
  content: "\e900";
}

.icon-apply-job:before {
  content: "\e901";
}

.icon-arrow-down:before {
  content: "\e902";
}

.icon-arrow-left:before {
  content: "\e903";
}

.icon-arrow-right:before {
  content: "\e904";
}

.icon-arrow-up:before {
  content: "\e905";
}

.icon-contact:before {
  content: "\e906";
}

.icon-down:before {
  content: "\e907";
}

.icon-download:before {
  content: "\e908";
}

.icon-left:before {
  content: "\e909";
}

.icon-linkedin:before {
  content: "\e90a";
}

.icon-loupe {
  transition: all .35s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}

.icon-loupe:before {
  content: "\e90b";
}

.icon-moins:before {
  content: "\e90c";
}

.icon-offer-finder:before {
  content: "\e90d";
}

.icon-phone:before {
  content: "\e90e";
}

.icon-play:before {
  content: "\e90f";
}

.icon-plus:before {
  content: "\e910";
}

.icon-right:before {
  content: "\e911";
}

.icon-send:before {
  content: "\e912";
}

.icon-tracking:before {
  content: "\e913";
}

.icon-twitter:before {
  content: "\e914";
}

.icon-up:before {
  content: "\e915";
}

.icon-youtube:before {
  content: "\e916";
}

.icon-close:before {
  content: '✕';
}

.icon-filter:before {
  content: "\e917";
}