.bandeau {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 392px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50%;
        background: linear-gradient(#000000bb, #00000000);
    }


    &_imageTexte {
        position: absolute;
        left: 50%;
        transform: translateX(calc(-50% + -20px));
        top: 47px;
        width: 282px;
        height: auto;
        max-width: 50%;
    }

    @include m1023 {
        height: 263px;

        &_imageTexte {
            top: 17px;
            width: 103px;
            transform: translateX(calc(-50% + -8px));
        }
    }

    @include m479{
        &_imageTexte {
            transform: translateX(calc(-50% + 10px));
        }
    }
}