.loader{
	border-radius: 10px;
	padding: 30px;
	display: flex;
	width: 100%;
	margin: 30px auto;
	color: $grey;
}

.loader-page{
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $bright;
	z-index: 1000000;
	height: 100%;
	width: 100%;

	&_center{
		display: flex;
		flex-direction: column;
		flex-direction: column;
		min-height: 200px;
		height: 20%;
		min-width: 200px;
		width: 20%;
		max-width: 300px;
	}

	&_spinner{
		flex: 1;
		// outline: 1px dashed;
		display: flex;
		align-self: center;
		color: pink;
		& *{
			&::before{
				display: block;
				font-size: 50px;
				content: '•';
				animation: loaderVibrate 1s infinite ;
			}


			&:nth-child(2)::before{
				animation-delay: 333ms;
				color: $grey;

			}
			&:nth-child(3)::before{
				animation-delay: 666ms;

			}
		}
	}
	@keyframes loaderVibrate {
		50%{
			transform: translate(0,-7px);
		}
		
	}

	transition: all 200ms ease;
	
	&[data-load=true]{
		opacity: 0;
		z-index: -10000;
	}
}