// COLORS
$bright : #ffffff;
$dark: #000000;
$dark2: #061115;
$grey: #d0d0d0;
$grey2: #d8d8d8;
$blue-medium: #9FB2B9;
$blue: #00a6d9;
$blue2: #006E90;
$blue3: #0A6582;
$blue-medium2: #0d4d63;
$blue-dark: #15252C;
$pink: #dc0059;
$pink2: #A50043;
$pink3: #781242;


// FONTS
$main-font: 'Nunito Sans', sans-serif;
$font-title: 'Nunito Sans', sans-serif;

// ICOMOON
$icon-address: "\e900";
$icon-apply: "\e901";
$icon-arrow: "\e902";
$icon-arrow: "\e903";
$icon-arrow: "\e904";
$icon-arrow: "\e905";
$icon-contact: "\e906";
$icon-down: "\e907";
$icon-download: "\e908";
$icon-left: "\e909";
$icon-linkedin: "\e90a";
$icon-loupe: "\e90b";
$icon-moins: "\e90c";
$icon-offer: "\e90d";
$icon-phone: "\e90e";
$icon-play: "\e90f";
$icon-plus: "\e910";
$icon-right: "\e911";
$icon-send: "\e912";
$icon-tracking: "\e913";
$icon-twitter: "\e914";
$icon-up: "\e915";
$icon-youtube: "\e916";