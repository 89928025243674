.flex_video {
    //margin-top: 84px;

    &_bloc{
        position: relative;
    }

    video {
        width: 100%;
    }

    &_youtube{
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        iframe{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    button {
        width: 80px;
        height: 80px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border: solid 13px transparent;
            border-left: solid 21px $bright;
            border-right-width: 0;
            left: calc(50% + 3px);
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}