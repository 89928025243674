.player{
    position: absolute;
    top: 0;
    
    @include m1459{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.15);
        padding-top: 25px;
        margin-bottom: 30px;
        border-radius: 10px;
    }

    &-playBtn{
        max-width: 100px;
        margin-top: 60px;

        @include m1459{
            max-width: 160px;
        }

        #Picto_Play{
            display: none;
            @include m1459{
            margin-left: 10px;
            display: block;
            }
        }

        .Intro_play{
            display: block;
            max-width: 70%;
            @include m1459{
                display: none;
            }
        }

        .micro{
            max-width: 50%;
            @include m1459{
                max-width: 60%;
            }

            
        }

        .timer{
            margin-top: 10px;
            font-size: 17px;
        }
    }

    &-introBtn{

        display: none;
        @include m1459{
            display: block;
            max-width: 160px;
            border-radius: 50px;
            border: solid 3px #fff;
            padding: 10px 0;
            margin: 25px 0;
            cursor: pointer;
        }

        p{
            font-size: 14px;
        }
    }
}