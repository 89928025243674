.template-home__container {
	background-color: $blue-dark;
	color: $bright;
	min-height: 100vh;
	font-family: $main-font;
	@include m991 {
		padding: 0px 16px;
		max-width: 100%;
		overflow: hidden;
	}
	.template-home-header {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: auto;
		min-height: 65vh;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 50%;
			background: linear-gradient(#000, transparent);
		}
		@include m991 {
			border-radius: 7px;
		}
		.template-home-header-logo {
			margin: 0 auto;
			padding-top: 80px;
			max-width: 185px;
			position: relative;

			@include m991 {
				padding-top: 47px;
				max-width: 155px;
			}
		}

		.template-home-header-title {
			text-align: center;
		}
	}

	.template-home-content {
		margin: 0 auto;
		padding: 45px 30px 50px;

		@include m991 {
			padding: 30px;
		}

		.template-home-content-title {
			font-size: 30px;
			font-weight: 800;
			line-height: 1.37;
			text-align: center;
			margin-bottom: 20px;

			@include m991 {
				font-size: 24px;
				font-weight: 800;
				line-height: 1.2;
				margin-bottom: 15px;
			}

			&.left{
				text-align: left;
			}
		}

		.template-home-content-text {
			font-size: 17px;
			line-height: 1.12;
			text-align: center;
			margin-bottom: 20px;
		}

		.template-home-content-parcours{
			margin: 65px 0;
			display: flex;
			justify-content: center;
			gap: 30px;
			position: relative;

			@include m991 {
				margin: 50px 0;
				flex-direction: column;
				align-items: center;
				gap: 15px;
			}

			&-btn{
				margin: 0;
				padding: 14px 25px;
				border-radius: 9px;
				background-color: rgba(0, 0, 0, 0.15);
				min-width: 270px;
				font-size: 17px;
				font-weight: 700;
				cursor: pointer;
				position: relative;

				span{
					position: absolute;
					right: 5px;
					top: 50%;
					transform: translateY(-50%);

					@include m991 {
						position: absolute;
						right: -25px;
						top: 50%;
						transform: translateY(-50%);
						filter: invert(0);
						cursor: initial;
					}

					img{
						filter: invert(1);
						margin-left: 5px;
						width: 15px;
						height: 15px;
						opacity: 0.5;

						
					}
				}

				&.hover{
					z-index: 200;
				}
				
				p{
					display: flex;
					align-items: center;
					opacity: 0.5;
					justify-content: center;
					text-align: center;
				}

				&.active{
					background-color: $bright;
					color: $blue-dark;

					img{
						filter: invert(0);
						opacity: 1;

						@include m991 {
							filter: invert(1);
						}
					}

					p{
						display: flex;
						align-items: center;
						opacity:1;				
					}
				}
			}

			.parcours-overlayInfos{
				position: absolute;
				pointer-events: none;
				margin-top: 20px;
				margin-left: 100px;
				visibility: hidden;
				
				img{
					max-width: 80px;
					filter: invert(0);
				}

				p{
					opacity: 1;
					max-width: 250px;
					margin-top: 20px;
					color: #fff;
				}

				&.active{
					visibility: visible;
				}

			}
			
		}

		.template-home-content-collections {

			@include m991 {
				padding: 8px 0px 0px 0px;
			}
			.collection-list {
				display: flex;
				flex-direction: column;
				&.order-1, &.order-2 {
					li:nth-child(3) {
						order: -1;
					}
				}
				&.order-3, &.order-4 {
					li:nth-child(1) {
						order: -1;
					}
				}
				&.order-5 {
					li:nth-child(2) {
						order: -1;
					}
				}
				&.order-6 {
					li:nth-child(4) {
						order: -1;
					}
				}
			}

			&.active-step {
				&:after {
					background: $blue-dark;
				}
			}

			.tuto-explication__container {
				display: none;
				top: -30px;
				left: 0;
				max-width: 480px;
				flex-direction: row-reverse;
				transform: translateY(-100%);

				img {
					transform: rotate(190deg);
					margin-top: 25px;
				}
			}

			.collection-title {
				font-size: 30px;
				font-weight: 800;
				line-height: 1.37;
				text-align: left;
				margin-bottom: 20px;

				@include m991 {
					font-size: 24px;
					line-height: 1.33;
					margin-bottom: 10px;
				}
			}
		}
	}

	&--surplace {
		.template-home-header {
			@include m991 {
				background-color: transparent !important;
				min-height: auto;
				&:before {
					display: none;
				}
			}
		}

		.template-home-content {
			@include m991 {
				padding: 14px 0 30px;
			}
		}
	}

	.template-home-overlay{
		position: fixed;
		z-index: 100;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		visibility: hidden;
		background-color: rgba(0, 0, 0, 0.8);

		&.active{
			visibility: visible;
		}
	}
}
