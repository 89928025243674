.flex_slideshow {
    //margin-top: 60px;
    //padding-bottom: 34px;

    img {
        width: 100%;
    }

    .slick-dots {
        bottom: -25px;

        li {
            width: 12px;
            height: 12px;

            button {
                &:before {
                    content: "";
                    background-color: $bright;
                    border-radius: 50%;
                    opacity: 1;
                    width: 12px;
                    height: 12px;
                    opacity: 0.3;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .slick-arrow {
        display: inline-block;
        width: 17px;
        height: 17px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        transform: rotate(-45deg);

        &:before {
            display: none;
        }
    }

    .slick-next {
        transform: rotate(135deg);
    }

    @include m1023 {
        //margin-top: 26px;
        //padding-bottom: 18px;

        .slick-arrow{
            display: none !important;
        }
    }
}