.pano-image__container {
    width: 100%;
    margin: 0 auto;
    height: 720px;
    max-height: 70vh;
    overflow: hidden;
    border-radius: 0px;
    @media screen and (max-width: 991px) {
        border-radius: 10px;
    }
}