.listeArticles {
    &_liste {}

    &_item {
        border-top: solid 1px rgba(255, 255, 255, 0.16);
        padding: 57px 0;
    }

    &_container {
        display: flex;
        justify-content: space-between;
    }

    &_visuel {
        width: 224px;
        height: 130px;
        border-radius: 8px;
        flex: 0 0 224px;
        overflow: hidden;

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 0.35s ease;
        }
    }

    &_content {
        flex: 0 0 calc(100% - 291px);

        &--noimage {
            flex: 0 0 100%;
        }
    }

    &_titre {
        color: $bright;
        font-weight: 800;
        font-size: 18px;
        line-height: 1.166666em;
        margin-bottom: 17px;
    }

    &_texte {
        color: $grey2;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.364em;
        margin-bottom: 28px;
    }

    &_link {
        span {
            display: inline-block;
            padding: 8px 28px;
            background-color: var(--colored);
            color: $bright;
            font-weight: 700;
            font-size: 17px;
            line-height: 1.294em;
            border-radius: 5px;
            transition: color 0.35s ease, background 0.35s ease;
        }
    }

    &_container:hover &_link,
    &_container:focus &_link {
        span {
            background-color: var(--colored2);
            color: rgba(255, 255, 255, 0.72);
        }
    }

    &_container:hover &_visuel,
    &_container:focus &_visuel {
        img {
            transform: scale(1.2);
        }
    }

    @include m1023 {
        padding-bottom: 126px;

        &_container {
            flex-direction: column;
        }

        &_item {
            padding: 85px 0;
        }

        &_visuel {
            flex: 0 0 130px;
            margin-bottom: 42px;
        }

        &_titre {
            font-weight: 700;
            font-size: 18px;
            line-height: 1.8333333em;
            margin-bottom: 7px;
        }

        &_texte {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.625em;
            margin-bottom: 27px;
        }
    }
}