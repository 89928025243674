#video {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
}

#canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: none;
  display: inline;
  transform: scale(2);
  pointer-events: none;
  @include m1023 {
    height: auto;
    width: 100%;
    object-fit: initial;
    transform: scale(1.5);
  }
}