.header {
  padding: 10px 0;
  color: $bright;
  position: absolute;
  width: 100%;
  top: 45px;
  z-index: 1;
  pointer-events: none;

  @include m991 {
    top: 15px;
    pointer-events: none;
  }

  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  &_lang {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    width: 40px;
    pointer-events: all;

    position: absolute;
    right: 115px;
    top: -7px;

    opacity: 0.25;
    // pointer-events: none;

    &.active-step {

      &:after {
        background: $blue-medium2;
      }

      .tuto-explication__container {
        flex-direction: row-reverse;
        width: 500px;
        max-width: 70vw;
        right: 0;
        top: 100px;
        align-items: baseline;

        @include m991 {
          top: 80px;
          right: 20px;
        }

        p {
          text-align: right;
        }

        img {
          z-index: -1;
          transform: scaleX(-1);
        }

      }
    }

    &-selected {
      margin: 10px 0px;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.33;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;

      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 47%;
        transform: translateY(-50%);
        right: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $bright;
      }
    }

    &-list {
      display: none;
      flex-direction: column;
      align-items: flex-start;

      &.open {
        display: flex;
        margin-bottom: 10px;
      }
    }

    &-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      height: 25px;
      width: 30px;
      color: $bright;
      border: 1px solid transparent;
      font-size: 16px;
      font-weight: bold;
      opacity: .5;
      transition: all ease-out 200ms;
      cursor: pointer;

      &[data-lang-active="true"] {
        opacity: 1;
      }
    }

    @include m1023 {
      transform: translateY(-20px);
      pointer-events: all;
      right: 15px;
      top: 21px;

      &-selected {
        &:after {
          right: 5px;
        }
      }
    }
  }
}

.app.home .header_lang,
.app.home2 .header_lang {
  display: none;
}

html[lang="en"] .app.content-page-lang .navigation {
  display: none;
}