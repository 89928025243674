.templateHome2 {
    background-color: $blue-dark;
    color: #fff;

    &_bandeau {
        height: 392px;
        background-color: transparent;
        background-repeat: no-repeat, no-repeat;
        background-position: 48.5% 57px, center center;
        background-size: 282px auto, cover;
    }

    &_content {
        padding-bottom: 90px;
    }

    &_select {
        display: flex;
        justify-content: center;
        padding-top: 44px;
        padding-bottom: 60px;

        &_button {
            font-weight: 700;
            font-size: 17px;
            line-height: 1.35em;
            background-color: #061115;
            color: rgba(255, 255, 255, 0.72);
            border-radius: 10px;
            padding: 14px 59px 14px 57px;
            margin: 10px;
            position: relative;
            transition: background 0.35s ease;

            .withPicto {
                display: flex;

                &:before {
                    content: "";
                    margin-right: 9px;
                    background-repeat: no-repeat;
                    background-position: center left;
                    filter: invert(88%) sepia(0%) saturate(0%) hue-rotate(158deg) brightness(85%) contrast(87%);
                }
            }

            &--campus {
                .withPicto {
                    &:before {
                        background-image: url(/assets/img/picto_campus.svg);
                        width: 15px;
                        height: 19px;
                    }
                }
            }

            &--salarie {
                .withPicto {
                    &:before {
                        background-image: url(/assets/img/picto_salarie.svg);
                        width: 19px;
                        height: 18px;
                    }
                }
            }

            &--selected {
                color: #fff;

                .withPicto {
                    &:before {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(322deg) brightness(105%) contrast(102%);
                    }
                }
            }

            &--campus:hover{
                background-color: $pink3;
            }

            &--salarie:hover{
                background-color: $blue3;
            }

            &--selected#{&}--campus {
                background-color: $pink;
            }

            &--selected#{&}--salarie {
                background-color: #00A6D9;
            }
        }
    }

    &_box {
        padding-bottom: 29px;

        &_item {
            display: flex;
            justify-content: space-between;

            @include m768 {
                align-items: center;
            }

            &--visible {
                display: flex;
            }

            &--hidden {
                display: none;
            }
        }

        &_col {
            &:nth-child(1) {
                flex: 0 0 47%;
            }

            &:nth-child(2) {
                flex: 0 0 44.7%;
                //padding-top: 126px;
            }
        }

        &_titre {
            font-weight: 700;
            font-size: 27px;
            line-height: 1.45em;
            letter-spacing: -0.003em;
            margin-bottom: 26px;
        }

        &_chapeau {
            font-weight: 700;
            font-size: 20px;
            line-height: 1.5em;
            margin-right: 60px;
        }

        &_frise {
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 218px;
            height: 277px;
            display: none;
            margin-top: 5px;

            &--campus {
                background-image: url(/assets/img/frise_rouge.png);
            }

            &--salarie {
                background-image: url(/assets/img/frise_bleu.png);
            }

            &--selected {
                display: block;
            }
        }
    }

    &_entries {
        &_item {
            border-left: solid 1px #000;
            border-bottom: solid 1px #000;
            padding-bottom: 30px;
            padding-top: 34px;
            cursor: pointer;

            &:first-child {
                padding-top: 6px;
            }

            &:last-child {
                padding-bottom: 0px;
                border-bottom: none;
            }

            &>a {
                display: flex;
                padding-left: 54px;
                align-items: flex-start;
                justify-content: space-between;
            }
        }

        &_titre {
            font-weight: 800;
            font-size: 16px;
            line-height: 1.35em;
            flex: 0 0 30%;
        }

        &_texte {
            font-weight: 700;
            font-size: 14px;
            line-height: 1.45em;
            flex: 0 0 40%;
            margin-left: 22px;
        }

        &_bouton {
            flex: 0 0 46px;
            align-self: center;

            &Item {
                opacity: 0.2;
                transition: opacity 0.35s ease;

                .campus & {
                    background-color: $pink;
                }

                .salarie & {
                    background-color: #00A6D9;
                }

                background-image: url(/assets/img/icon_fleche.svg);
                background-repeat: no-repeat;
                background-position: center center;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                display: block;
            }
        }

        &_item a:hover{
            .templateHome2_entries_boutonItem{
                opacity: 1;
            }
        }
    }

    &_qr {
        display: flex;
        position: relative;
        padding-bottom: 66px;
        align-items: center;
        justify-content: space-between;

        &_col {
            &:nth-child(1) {
                flex: 0 0 38.7%;
                padding-right: 4.6%;
                text-align: right;
                padding-top: 55px;
                padding-bottom: 94px;
                background: transparent url(/assets/img/qr_fleche.png) no-repeat bottom right;
                background-size: 113px 214px;
            }

            &:nth-child(2) {
                flex: 0 0 50.6%;
            }
        }

        &_titre {
            font-weight: 800;
            font-size: 25px;
            line-height: 1.55em;
            margin-bottom: 10px;
            letter-spacing: 0.04em;
        }

        &_chapeau {
            font-weight: 700;
            font-size: 20px;
            line-height: 1.5em;
        }

        &_visuel {
            border-radius: 24px;
        }

        &_code {
            border-radius: 16px;
            position: absolute;
            width: 190px;
            height: 190px;
            bottom: 0;
            left: 49.7%;
            transform: translateX(-50%);
        }
    }

    @include m767 {
        &_bandeau {
            height: 263px;
            background-position: 50% 61%, center center;
            background-size: 187px auto, cover;
        }

        &_content {
            padding-bottom: 86px;
        }

        &_select {
            flex-direction: column;
            position: sticky;
            top: 0;
            z-index: 10;
            align-items: center;
            height: 238px;

            &_item {
                width: 100%;
            }

            &.sticky {
                flex-direction: row;
                align-items: flex-start;
                padding-top: 0;
                padding-bottom: 104px;
                width: 100%;
                pointer-events: none;

                .withTexte {
                    display: none;
                }

                .withPicto {
                    justify-content: center;

                    &:before {
                        background-position: center center;
                        margin-right: 0;
                    }
                }
            }

            &_item {
                .sticky & {
                    flex: 0 0 50%;
                    pointer-events: all;
                }
            }

            &_button {
                margin: 10px 0;
                width: 100%;

                .sticky & {
                    margin: 0;
                    width: 100%;
                    padding-bottom: 12px;
                    border-radius: 0;
                    height: 45px;

                    &--campus {
                        border-radius: 0 0 0 10px;
                    }

                    &--salarie {
                        border-radius: 0 0 10px 0;
                    }
                }

                .withPicto {
                    justify-content: center;
                }
            }
        }

        &_box {
            padding-bottom: 29px;

            &_item {
                flex-direction: column;
            }

            &_titre {
                font-size: 20px;
                line-height: 1.5em;
                margin-bottom: 15px;

                br {
                    display: none;
                }
            }

            &_chapeau {
                font-size: 17px;
                line-height: 1.59em;
                margin-right: 0;

                br {
                    display: none;
                }
            }

            &_col {
                &:nth-child(2) {
                    padding-top: 38px;
                }
            }

            &_frise {
                background-size: cover;
                height: 40vw;
                margin-top: 40px;
            }
        }

        &_entries {
            &_item {
                padding-bottom: 27px;
                padding-top: 32px;
                border-left: none;

                & > a {
                    align-items: center;
                    padding-left: 0;
                }
            }

            &_titre {
                flex: 0 0 60%;
            }

            &_texte {
                display: none;
            }
        }

        &_qr {
            flex-direction: column;
            background: transparent url(/assets/img/qr_fleche.png) no-repeat -20px 78%;
            background-size: 113px 214px;
            display: none;
            
            &_col {
                &:nth-child(1) {
                    order: 2;
                    text-align: left;
                    background: none;
                    padding-top: 34px;
                }
            }

            &_visuel {
                border-radius: 8px;
            }

            &_code {
                left: auto;
                right: 6.5%;
                transform: none;
                width: 132px;
                height: 132px;
                border-radius: 8px;
            }

            &_titre {
                font-size: 20px;
                line-height: 1.5em;
                letter-spacing: 0.0em;
                margin-bottom: 21px;
            }

            &_chapeau {
                font-size: 17px;
                line-height: 1.59em;

                br {
                    display: none;
                }
            }
        }
    }
}